<template>
  <div id="container">
    <transition name="login-fade">
      <Login v-if="token === ''"/>
    </transition>

    <transition name="login-fade">
      <a-layout v-if="token !== ''">
        <a-layout-header
            :style="{ position: 'fixed', zIndex: 1, width: '100%',display:'flex',justifyContent: 'center' }">
          <div class="logo">
            <img src="/images/logo.png" alt="logo">
          </div>
          <a-menu
              mode="horizontal"
              :selectedKeys="menuSelect"
              :style="{ lineHeight: '61px' }"
              @select="selectMenu"
          >
            <a-menu-item key="/">Home</a-menu-item>
            <a-sub-menu>
              <span slot="title">Expression Abundance</span>
              <a-menu-item key="/expression_overview/single_gene">Single Gene</a-menu-item>
              <a-menu-item key="/expression_overview/multiple_gene">Multiple Gene</a-menu-item>
              <a-menu-item key="/expression_overview/mic_rna_expression">MicRNA Expression</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
              <span slot="title">Differential Expression</span>
              <a-menu-item key="/diff-expression/mRNA-Seq">mRNA-Seq</a-menu-item>
              <a-menu-item key="/diff-expression/microRNA-Seq">microRNA-Seq</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
              <span slot="title">Co-Expression</span>
              <a-menu-item key="/WGCNA">RNA-Seq WGCNA</a-menu-item>
              <a-menu-item key="/miRNA-WGCNA">miRNA-Seq WGCNA</a-menu-item>
              <a-menu-item key="/RNA-array-WGCNA">RNA-array WGCNA</a-menu-item>
            </a-sub-menu>
            <a-menu-item key="/miRNA_target_search">miRNA Target search</a-menu-item>
            <a-menu-item key="/help">Help</a-menu-item>

            <!--            <a-menu-item key="/dataset">数据集</a-menu-item>-->
            <!--            <a-sub-menu>-->
            <!--              <span slot="title">analysis</span>-->
            <!--              <a-menu-item key="/query">Different Expression Analysis</a-menu-item>-->
            <!--              <a-menu-item key="/expression_overview">Expression OverView</a-menu-item>-->
            <!--              <a-menu-item key="/WGCNA">WGCNA</a-menu-item>-->
            <!--            </a-sub-menu>-->
            <!--            <a-menu-item key="/statistics">Statistics</a-menu-item>-->
            <!--            <a-menu-item key="/help">Help</a-menu-item>-->
            <!-- <a-menu-item key="/asyn_task_list">异步任务列表</a-menu-item> -->
          </a-menu>

          <div class="user" style="display: none">
            <a-tooltip>
              <template slot="title">打开帮助文档/页面</template>
              <a-icon type="question-circle" theme="filled"/>
            </a-tooltip>

            <a-dropdown>
              <a @click="(e) => e.preventDefault()">
                <a-icon type="user"/>
                <span class="username">张三</span>
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;">退出</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-layout-header>
        <a-layout-content>
          <!--          <a-breadcrumb :routes="breadcrumb">-->
          <!--            <template slot="itemRender" slot-scope="{ route, params, routes, paths }">-->
          <!--              <span-->
          <!--                  v-if="routes.indexOf(route) === routes.length - 1 || route.name === 'BGED' || route.path === undefined"-->
          <!--              >{{ route.name }}</span>-->
          <!--              <router-link v-else :to="route.path">{{ route.name }}</router-link>-->
          <!--            </template>-->
          <!--          </a-breadcrumb>-->

          <div class="router-content">
            <div class="router-view">
              <router-view></router-view>
            </div>

            <div class="footer">Copyright © Ningxia Key Laboratory of Ruminant Molecular and Cell Breeding
              Copyright
            </div>
          </div>

          <a-popover placement="topRight" trigger="click" :visible="taskPopoverVisible"
                     @visibleChange="clickTaskPopover" :destroyTooltipOnHide="true">
            <template slot="content">
              <AsynTaskList/>
            </template>
            <template slot="title">
              <div style="padding: 0.3rem;font-size: 0.7rem;font-weight: bold;">异步任务列表</div>
            </template>
            <a-avatar shape="square" size="large" class="sync-task-fixed" style>Task</a-avatar>
          </a-popover>
        </a-layout-content>
        <!--        <a-layout-footer>Copyright © Ningxia Key Laboratory of Ruminant Molecular and Cell Breeding Copyright</a-layout-footer>-->
      </a-layout>
    </transition>
  </div>
</template>

<script>
import Login from "../components/Login.vue";
import {mapGetters} from "vuex";
import AsynTaskList from "./query/AsynTaskList.vue";
import bus from '@/utils/bus'

export default {
  name: "Layout",
  data() {
    return {
      menuSelect: [],
      taskPopoverVisible: false
    };
  },
  components: {
    Login,
    AsynTaskList
  },
  created() {
    this.menuSelect = [this.$route.path]
  },
  mounted() {
    bus.$on("TaskPopoverVisible", (bool) => {
      this.taskPopoverVisible = bool;
    })

    // 导航栏菜单切换
    bus.$on("menuSelect", (path) => {
      this.selectMenu({key: path})
    })
  },
  computed: {
    ...mapGetters(["token"]),
    breadcrumb() {
      if (this.$route.meta.breadcrumb[0].name !== "BGED") {
        this.$route.meta.breadcrumb.unshift({
          name: "BGED"
        })
      }
      return this.$route.meta.breadcrumb;
    }
  },
  methods: {
    selectMenu(menu) {
      this.menuSelect = [menu.key];
      if (this.$route.path !== menu.key) {
        //获取路由对象并切换
        this.$router.push(menu.key)
      }
    },
    clickTaskPopover(visible) {
      this.taskPopoverVisible = visible;
    },
  },
};
</script>

<style lang="less" scoped>
#container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .ant-layout {
    width: 100vw;

    &-header {
      background: #fff;

      .ant-menu-item {
        font-size: 18px;
        padding: 0;
        margin: 0 35px;

      }

      .ant-menu-submenu {
        font-size: 18px;
        padding: 0;
        margin: 0 35px;
      }

      .ant-menu-horizontal {
        border-bottom: 0 !important;
      }
    }

    .logo {
      width: 257px;
      height: 64px;
      line-height: 64px;
      text-align: center;
      color: #fff;
      font-size: 1rem;
      margin-right: 2rem;
      float: left;

      img {
        width: 100%;
        height: auto;
      }
    }

    .user {
      position: absolute;
      right: 50px;
      top: 0;
      color: rgba(255, 255, 255, 0.65);

      a {
        color: rgba(255, 255, 255, 0.65);
      }

      .anticon {
        font-size: 18px;
        margin-left: 10px;
      }
    }

    .ant-layout-content {
      width: 100vw;
      height: calc(100vh - 64px);
      margin-top: 64px;

      .ant-breadcrumb {
        height: 50px;
        line-height: 50px;
      }

      .router-content {
        background: #fff;
        //padding: 20px;
        height: 100%;
        overflow: auto;

        .router-view {
          height: auto;
          min-height: calc(100% - 100px);
          background: #F9F9FB;
          overflow: hidden;
        }
      }

      .sync-task-fixed {
        background: @primary-color; //#00a2ae;
        vertical-align: middle;
        position: fixed;
        bottom: 1rem;
        right: 1.5rem;
        cursor: pointer;

        &:hover {
          border-radius: 50%;
          transform: scale(1.1);
          -moz-transition: ease-out 0.3s;
          -o-transition: ease-out 0.3s;
          -webkit-transition: ease-out 0.3s;
          transition: ease-out 0.3s;
        }
      }

      .footer {
        text-align: center;
        background: #fff;
        font-size: 18px;
        //font-weight: 600;
        height: 100px;
        line-height: 100px;
      }
    }

    //.ant-layout-footer {
    //  text-align: center;
    //  padding: 2rem 50px;
    //  background: #fff;
    //  min-height: 5.3rem;
    //}
  }
}

.login-fade-enter-active {
  transition: all 1s ease;
}

.login-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.login-fade-enter,
.login-fade-leave-to {
  opacity: 0;
}
</style>
