<template>
  <div id="asyn-task-list">
    <a-table :columns="columns" :data-source="dataSource" rowKey="task_id" size="small" :scroll="{x:true}">
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 0.5rem;"
      >
        <a-input
            v-ant-ref="c => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            size="small"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">Reset</a-button>
      </div>
      <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="customRender" slot-scope="text, record, index, column">
        <a
            v-if="searchText && searchedColumn === column.dataIndex"
            @click="goToResult(record)"
            :style="{'color':record.status === 'Failed' ? '#d90214' : '#40a9ff'}"
        >
          <template
              v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
            >{{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </a>
        <template v-else>
          <a :style="{'color':record.status === 'Failed' ? '#d90214' : '#40a9ff'}"
             @click="goToResult(record)">{{ text }}</a>
        </template>
      </template>

      <span slot="status" slot-scope="text, record">
                <a-badge v-if="record.status === 'Waiting'" status="warning" :text="record.status"/>
                <a-badge v-else-if="record.status === 'Successed'" status="success" :text="record.status"/>
                <a-badge
                    v-else-if="record.status === 'Running'"
                    status="processing"
                    :text="record.status"
                />
                <a-badge v-else status="error" :text="record.status"/>
            </span>

      <span slot="action" slot-scope="text, record" class="action-icon">
                <a-popconfirm
                    placement="bottom"
                    ok-text="删除"
                    cancel-text="取消"
                    title="确定删除该任务？"
                    @confirm="delTask(record)"
                >
                    <a-icon type="delete"/>
                </a-popconfirm>
            </span>
    </a-table>
  </div>
</template>

<script>
import {timestampToDateTime, setDuringTime} from '@/utils/date-time.js'
import {syncTaskListStorageRemoveTask} from '@/utils/index.js'
import Bus from '@/utils/bus'

export default {
  name: "LayoutDataSet",
  data() {
    return {
      dataSource: [],
      columns: [
        {
          title: "类型",
          dataIndex: 'type',
          key: 'type',
          width: 130,
          filters: [
            {text: '差异分析', value: '差异分析'},
            {text: '富集分析', value: '富集分析'},
            {text: '基因表达量', value: '基因表达量'},
          ],

          // filteredValue: filteredInfo.type || null,
          onFilter: (value, record) => record.type.includes(value),
        },
        {
          title: '作业ID',
          dataIndex: 'task_id',
          key: 'task_id',
          width: 280,
          scopedSlots: {customRender: "task_id"},
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) => record.task_id.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          }
        },
        {
          title: 'Start Time',
          dataIndex: 'start_time',
          key: 'start_time',
          width: 160,
          sorter: (a, b) => a.start_time < b.start_time ? 1 : -1,
          customRender: (text) => timestampToDateTime(text)
        },
        {
          title: 'End Time',
          key: 'end_time',
          dataIndex: 'end_time',
          width: 160,
          sorter: (a, b) => a.end_time < b.end_time ? 1 : -1,
          customRender: (text) => {
            if (text === "") return "";
            return timestampToDateTime(text);
          }
        },
        {
          title: 'Run Time',
          key: 'run_time',
          dataIndex: "run_time",
          width: 100,
          customRender: (text, row) => setDuringTime(text, row.start_time)
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: "status",
          width: 110,
          scopedSlots: {customRender: 'status'}
        },
        {
          key: 'action',
          align: "center",
          scopedSlots: {customRender: 'action'},
        },
      ],
      /* 表格搜索 */
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      /* 表格搜索 End */
    };
  },
  created() {
    this.dataSource = JSON.parse(window.localStorage.getItem("sync_task_list"));

    Bus.$on("taskListData", () => {
      this.dataSource = JSON.parse(window.localStorage.getItem("sync_task_list"));
    })
  },
  methods: {
    //表格搜索
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    //重置搜索
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    //移除任务
    delTask(record) {
      syncTaskListStorageRemoveTask(record.task_id);

      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter(item => item.task_id !== record.task_id);

      this.$message.success("已删除！");
    },
    //点击作业ID跳转到相应的结果页面
    goToResult(record) {
      const h = this.$createElement;

      if (record.status === "Waiting") {
        this.$message.info("任务正在队列等待...");
        return;
      }
      if (record.status === "Running") {
        this.$message.info("任务正在执行中...");
        return;
      }
      if (record.status === "Failed") {
        // this.$message.error("任务已失败！");
        Bus.$emit("TaskPopoverVisible", false);
        this.$error({
          width: 520,
          title: '任务已失败',
          class: "taskFailedInfoModal",
          content: h('div', {}, [
            h('p', '任务ID：'),
            h('p', {style: {'text-indent': '2em'}}, record.task_id),
            h('p', '错误信息：'),
            h('p', {style: {'text-indent': '2em'}}, record.error),
          ])
        });
        return;
      }

      Bus.$emit("TaskPopoverVisible", false);

      switch (record.type) {
        case "差异分析":
          window.localStorage.setItem("current_diff_task", JSON.stringify(record))
          this.taskRouterPush("/query_result")
          break;
        case "富集分析":
          window.localStorage.setItem("current_enrichment_task", JSON.stringify(record))
          this.taskRouterPush("/enrichment_analysis")
          break;
        case "基因表达量":
          window.localStorage.setItem("current_gene_expression_task", JSON.stringify(record))
          this.taskRouterPush("/gene_expression")
          break;
        case "基因表达量(热图)":
          window.localStorage.setItem("current_gene_expression_task", JSON.stringify(record))
          this.taskRouterPush("/gene_expression_hot_map")
          break;
        /* V2 */
        case "micRNA-差异分析":
          window.localStorage.setItem("current_diff_micRNA_task", JSON.stringify(record))
          this.taskRouterPush("/micRNA_query_result")
          break;
        case "micRNA-基因表达量":
          window.localStorage.setItem("current_gene_expression_micRNA_task", JSON.stringify(record))
          this.taskRouterPush("/gene_expression_micRNA")
          break;
        case "micRNA-富集分析":
          window.localStorage.setItem("current_enrichment_micRNA_task", JSON.stringify(record))
          this.taskRouterPush("/micRNA_enrichment_analysis")
          break;
      }
    },
    taskRouterPush(route) {
      //如果是目标路由就是当前路由
      if (this.$route.path === route) {
        this.$router.replace({path: "/refresh"})
      } else {
        this.$router.push(route)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#asyn-task-list {
  position: relative;

  ::v-deep .ant-table {
    margin-top: 0;
  }

  ::v-deep .ant-pagination {
    margin: 0 !important;
    padding: 1rem !important;
  }

  .action-icon {
    .anticon {
      font-size: 1rem;
      cursor: pointer;
    }
  }
}
</style>

<style>
.taskFailedInfoModal {
  position: relative;
  z-index: 9999;
}
</style>
