<template>
  <div id="login">
    <div class="login-box">
      <div class="login-logo">niuweb</div>
      <a-form-model
        layout="horizontal"
        :model="loginForm"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input v-model="loginForm.user" placeholder="Username">
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input
            v-model="loginForm.password"
            type="password"
            placeholder="Password"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            html-type="submit"
            :disabled="loginForm.user === '' || loginForm.password === ''"
          >
            Log in
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        user: "",
        password: "",
      },
    };
  },
  methods: {
    handleSubmit(e) {
      this.$store.commit("user/SET_TOKEN",'111');
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  width: 100vw;
  height: 100vh;
  background: #001660;

  .login-box {
    position: absolute;
    left: 25vw;
    top: 30vh;
    width: 50vw;
    min-height: 50vh;
    text-align: center;

    .login-logo {
      font-size: 2rem;

      color: #fff;
    }
  }

  .ant-form {
    width: 300px;
    margin-left: calc(25vw - 150px);
  }
}
</style>