/**
 * @author 马海兵 [mlt131220@163.com]
 * @desc 日期时间相关操作工具
 */

/**
 * 获取当前日期，格式yyyyMMdd
 * @param dayNum 查多少天以前
 * @returns {*}
 */
export function getDate2yyyyMMdd(dayNum = 0) {
  const date = new Date()
  date.setTime(date.getTime() - dayNum * 24 * 60 * 60 * 1000)
  const Y = date.getFullYear().toString()
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return Y + M + D;
}

/**
 * 获取 参数日期月的最后一天
 * @param {String} date 日期 yyyyMMdd
 * @returns {String} 返回该月最后一天日期
 */
export function getMonthLastDay(date) {
  //先判断是不是当前月
  if (date.substr(0, 6) === getDate2yyyyMMdd().substr(0, 6)) {
    return getDate2yyyyMMdd();
  }
  //不是当前月的话
  const lastDay = new Date(date.substr(0, 4), date.substr(4, 2), 0);
  return dateFormat('yyyyMMdd', lastDay);
}

/**
 * 获取 参数日期年的最后一天
 * @param {String} date 日期 yyyyMMdd
 * @returns {String} 返回该年最后一天日期
 */
export function getYearLastDay(date) {
  const year = date.substr(0, 4);
  //先判断是不是当前年
  if (year === getDate2yyyyMMdd().substr(0, 4)) {
    return getDate2yyyyMMdd();
  }
  //不是当前年的话
  return year + "1231";
}

/**
 * 时间戳转换成日期
 * @param timestamp
 * @returns {*}
 */
export function timestampToDate(timestamp) {
  const date = new Date(timestamp) // 如果时间戳为10位数，timestamp改为timestamp*1000
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return Y + M + D
}

/**
 * 时间戳转换成时间
 * @param timestamp
 * @returns {*}
 */
export function timestampToTime(timestamp) {
  const date = new Date(timestamp)
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return h + m + s
}
/**
 * 时间戳转换成日期时间
 * @param timestamp
 * @returns {*}
 */
export function timestampToDateTime(timestamp) {
  return timestampToDate(timestamp) + ' ' + timestampToTime(timestamp)
}
/**
 * 计算日期与今天相差天数
 * @param date （xxxx-xx-xx格式)
 * @returns Number
 */
export function dateDifference(date) {
  let dateSpan,
    tempDate,
    iDays
  date = Date.parse(date)
  dateSpan = new Date() - date
  dateSpan = Math.abs(dateSpan)
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
  return iDays
}

/**
 * 时间格式化
 * @param {String} fmt
 * @param {Date} date
 * */
export function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

/**
 * 时间差转换
 * @param {number} during 时间戳差值
 */
export function setDuringTime(difference,start_time) {
  if (difference === ""){
    difference = new Date().getTime() - start_time;
  }

  const days = Math.floor(difference / (24 * 3600 * 1000)); //计算出相差天数
  const leave1 = difference % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000)); //计算相差分钟数
  const leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000)); //计算相差秒数
  const leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  const seconds = Math.round(leave3 / 1000);
  return `${days * 24 + hours || "00"}:${minutes || "00"}:${seconds}.${leave3 % 1000}`
}
